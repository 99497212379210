<template>
  <div class="ResultItemText">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.ResultItemText {
  color: var(--gray-2);
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 20px;
}
</style>
